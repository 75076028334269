import {
  FC,
  ComponentPropsWithoutRef,
  useState,
  KeyboardEvent,
  FocusEvent,
} from 'react';

import {
  InputAdornment,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  Box,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import TextInput from 'components/forms/TextInput';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof TextInput>, 'type'>;

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.error.main,
    borderRadius: '4px',

    customArrow: {
      color: theme.palette.error.main,
    },
  },

  arrow: {
    color: theme.palette.error.main,
  },
}));

/**
 * Отображает поле ввода пароля.
 */
const PasswordInput: FC<Props> = ({
  InputProps,
  onKeyDown,
  onFocus,
  onBlur,
  ...props
}) => {
  const classes = useStyles();

  const [isCapsLock, setCapsLock] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isFocused, setFocused] = useState(false);

  const typePassword = isVisible ? 'text' : 'password';

  function handleChangeVisibility() {
    setVisible((value) => !value);
  }

  function handleKeyDown(keyEvent: KeyboardEvent<HTMLInputElement>) {
    if (keyEvent.getModifierState('CapsLock')) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }

    onKeyDown?.(keyEvent);
  }

  function handleFocus(event: FocusEvent<HTMLInputElement>) {
    setFocused(true);
    onFocus?.(event);
  }

  function handleBlur(event: FocusEvent<HTMLInputElement>) {
    setFocused(false);
    onBlur?.(event);
  }

  return (
    <Tooltip
      title={
        <Box margin={1}>
          <Typography variant="subtitle2" gutterBottom>
            Включен CapsLock
          </Typography>
        </Box>
      }
      classes={classes}
      arrow
      placement="right"
      open={isFocused && isCapsLock}
    >
      <TextInput
        {...props}
        type={typePassword}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={handleChangeVisibility}
                color="inherit"
              >
                {isVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Tooltip>
  );
};

export default PasswordInput;
