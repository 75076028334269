/* eslint-disable react/jsx-no-duplicate-props */
import { FC, ComponentPropsWithoutRef, forwardRef } from 'react';

import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof TextField>,
  'name' | 'onChange'
> & {
  /**
   * Уникальное имя поля ввода.
   */
  name: string;

  /**
   * Обработчик события изменения значения.
   */
  onChange?: (value: any) => void;

  /**
   * Ссылка на элемент.
   */
  innerRef?: any;

  /**
   * Маска.
   */
  mask?: any;
};

/**
 * Свойства компонента маски.
 */
type PropsMask = {
  inputRef: any;
} & ComponentPropsWithoutRef<typeof MaskedInput>;

/**
 * Маска ввода.
 */
const TextMask: FC<PropsMask> = ({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
  />
);

/**
 * Отображает поле ввода текста.
 */
const TextInput: FC<Props> = ({
  inputProps,
  InputProps,
  inputRef,
  mask,
  ...props
}) => (
  <TextField
    {...props}
    inputProps={{ ...inputProps, mask }}
    InputProps={
      {
        ...InputProps,
        inputComponent: typeof mask === 'undefined' ? undefined : TextMask,
      } as any
    }
  />
);

export default forwardRef<HTMLElement, Props>((props, ref) => (
  <TextInput inputRef={ref} {...props} />
));
