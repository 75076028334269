import React, { FC } from 'react';

import { makeStyles, Typography, Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import NotAuthorizedOnly from 'components/auth/NotAuthorizedOnly';

import SubtitledBrandIcon from './logo/SubtitledBrandIcon';

/**
 * Определение темы для бэкраунда страниц логина.
 */
const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/background.jpg) no-repeat #000`,
    backgroundSize: 'cover',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    flex: 1,
  },

  inner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    flex: 1,

    paddingRight: 160,
    paddingLeft: 160,
  },

  caption: {
    color: theme.palette.primary.contrastText,
    zIndex: theme.zIndex.tooltip,
  },

  logo: {
    color: theme.palette.common.white,
    zIndex: theme.zIndex.tooltip,
    flex: 1,
  },

  content: {
    flex: 1,
  },
}));

/**
 * Предоставляет обёртку для страниц в разделе входа в систему.
 */
const LoginLayout: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <NotAuthorizedOnly>
      <Container className={classes.root} maxWidth={false}>
        <Container className={classes.inner} maxWidth={false}>
          <div className={classes.logo}>
            <SubtitledBrandIcon />
          </div>
          <div className={classes.content}>{children}</div>
        </Container>
        <Box p={2} className={classes.caption}>
          <Typography variant="caption">
            This site is protected by reCAPTCHA and the Google Privacy Policy
            and Terms of Service apply.
          </Typography>
        </Box>
      </Container>
    </NotAuthorizedOnly>
  );
};

export default LoginLayout;
