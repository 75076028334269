/**
 * Маска ввода текстового поля.
 */
enum Mask {
  /**
   * Маска кода подтверждения.
   */
  CODE = '000-000',

  /**
   * Маска телефона.
   */
  PHONE = '+7 (000) 000 0000',

  /**
   * Маска даты.
   */
  DATE = '0`0`.0`0`.0`0`0`0',

  /**
   * Маска серии и номера паспорта.
   */
  PASSPORT_SERIES_NUMBER = '0000 000000',

  /**
   * Маска кода подразделения, выдавшего паспорт.
   */
  PASSPORT_ISSUER_CODE = '000-000',

  /**
   * Маска СНИЛС.
   */
  SNILS = '000-000-000-00',
}

export default Mask;
