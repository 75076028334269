import { FC, ClipboardEvent, ComponentType } from 'react';

import { IMaskMixin } from 'react-imask';

import MaskHelper from 'helpers/MaskHelper';

/**
 * Свойства целевого компонента.
 *
 * Важно: казалось бы, отчего бы тут не указать constraint'ы для `value` и
 * `onChange` - так же правильнее! А вот хрен. Компонент TextField из
 * `@material-ui/core` тогда теряет половину свойств, так уж он типизирован.
 */
type InnerProps = {};

/**
 * Итоговые свойства компонента.
 *
 * Внимание: мы не производим здесь `Omit<TProps, 'onChange'>` не случайно.
 * Да, так мы рискуем получить неоднозначность в свойствах, но зато не ломается
 * типизация свойств компонентов из `@material-ui/core` (проверялось на
 * `TextField`).
 */
type OuterProps<TProps extends InnerProps> = TProps & {
  /**
   * Обрабатывает изменение текущего значения поля ввода.
   * @param value Новое значение.
   */
  onChange?: (value: string) => void;

  /**
   * Маска ввода, в которой '0' означает любую цифру, 'a' - любую букву и '*' -
   * любой символ.
   */
  mask: string;
};

/**
 * Возвращает обёртку над указанным компонентом поля ввода, которая добавляет
 * ему функционал ограничения ввода по маске.
 *
 * В компонент добавляется новое свойство `mask` - маска ввода. Также у
 * компонента меняется свойство `onChange`: вместо события в него теперь
 * передаётся просто новое значение поля ввода.
 * @param Target Компонент поля ввода.
 */
export default function withInputMask<TProps extends InnerProps>(
  Target: ComponentType<TProps>,
) {
  /**
   * Обёртка для подключения библиотеки imask.
   */
  // @ts-ignore
  const WrappedTarget = IMaskMixin(Target);

  /**
   * HOC.
   */
  const WithInputMask: FC<OuterProps<TProps>> = ({
    onChange,
    onPaste,
    mask,
    ...props
  }: any) => {
    function handlePaste(event: ClipboardEvent<HTMLInputElement>) {
      const element = event.target as HTMLInputElement;

      const { selectionStart, selectionEnd, value: inputValue } = element;
      const text = event.clipboardData.getData('Text');

      const isNeedCorrection =
        inputValue &&
        text &&
        selectionStart != null &&
        (selectionEnd === selectionStart || selectionEnd === mask.length) &&
        !MaskHelper.isSpecialChars(mask, 0, selectionStart);

      if (!isNeedCorrection) {
        if (onPaste) {
          onPaste(event);
        }

        return;
      }

      event.preventDefault();

      if (onChange) {
        const maskedText = MaskHelper.format(text, mask);
        onChange(maskedText);
      }
    }

    return (
      <WrappedTarget
        {...props}
        onAccept={onChange}
        onPaste={handlePaste}
        mask={mask}
        lazy
        overwrite
      />
    );
  };

  WithInputMask.displayName = `WithInputMask(${
    Target.displayName || Target.name
  })`;

  return WithInputMask;
}
