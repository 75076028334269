import { useRef } from 'react';

import ApiService from 'services/ApiService';

/**
 * Возвращает клиент API, используемый для незащищённых конечных точек.
 */
export default function useInsecureClient() {
  const clientRef = useRef(new ApiService());

  return clientRef.current;
}
