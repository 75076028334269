import { FC, ComponentPropsWithoutRef } from 'react';

import { Typography } from '@material-ui/core';

import Button from 'components/ui/Button';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Button>, 'children'> & {
  /**
   * Количество секунд, отображаемое на кнопке.
   */
  seconds: number;
};

/**
 * Отображает кнопку повторной отправки кода подтверждения.
 */
const LoginResendButton: FC<Props> = ({ seconds, disabled, ...props }) => (
  <Button {...props} disabled={disabled || seconds > 0}>
    <Typography component="span">
      {seconds > 0
        ? `Выслать код повторно через: ${seconds} сек.`
        : 'Выслать код повторно'}
    </Typography>
  </Button>
);

export default LoginResendButton;
