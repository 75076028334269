import { FC, ReactNode } from 'react';

import {
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
  Divider,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Заголовок.
   */
  title: ReactNode;

  /**
   * Подсказка в заголовке.
   */
  hint?: ReactNode;

  /**
   * Подзаголовок.
   */
  subtitle: ReactNode;

  /**
   * Действия.
   */
  actions: ReactNode;
};

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 512,
  },
}));

/**
 * Отображает карточку с контентом в разделе аутентификации.
 */
const LoginCard: FC<Props> = ({ title, hint, subtitle, actions, children }) => {
  const styles = useStyles();

  return (
    <Container className={styles.root}>
      <Card>
        <Box p={1}>
          <CardContent>
            <Grid
              justifyContent="space-between"
              alignItems="baseline"
              container
            >
              <Grid item>
                <Typography gutterBottom variant="h5" component="h2">
                  {title}
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="textSecondary"
                >
                  {hint}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" color="textSecondary" component="div">
              {subtitle}
            </Typography>
            <Box>{children}</Box>
          </CardContent>
          <Divider variant="middle" />
          <CardActions>
            <Box textAlign="center" flex={1} paddingTop={1}>
              {actions}
            </Box>
          </CardActions>
        </Box>
      </Card>
    </Container>
  );
};

export default LoginCard;
