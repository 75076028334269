import { FC } from 'react';

import Replace from 'components/router/Replace';
import Route from 'constants/Route';
import useSession from 'hooks/useSession';
import { useRouter } from 'next/router';

/**
 * Делает так, чтобы содержимое этого компонента было доступно только
 * неавторизованным пользователям. Прочие же будет перенаправлены в приватный
 * раздел.
 */
const NotAuthorizedOnly: FC = ({ children }) => {
  const router = useRouter();
  const { isAuthenticated } = useSession();

  const returnUrl =
    typeof router.query.returnUrl === 'string'
      ? router.query.returnUrl
      : Route.CONTRACTS_PORTFOLIO;

  if (!router.isReady) {
    return null;
  }

  if (isAuthenticated) {
    return <Replace to={returnUrl} />;
  }

  return <>{children}</>;
};

export default NotAuthorizedOnly;
