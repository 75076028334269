/**
 * Плейсхолдер для поля ввода.
 */
enum Placeholder {
  /**
   * Плейсхолдер поля ввода телефона.
   */
  PHONE = '+7 (900) 000 0000',

  /**
   * Плейсхолдер поля ввода кода подтверждения.
   */
  CODE = '000-000',

  /**
   * Плейсхолдер даты.
   */
  DATE = 'дд.мм.гггг',
}

export default Placeholder;
