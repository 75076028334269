import React, { FC } from 'react';

import { Box, TextField } from '@material-ui/core';
import * as Yup from 'yup';

import Button from 'components/ui/Button';
import FieldErrorText from 'constants/FieldErrorText';
import Mask from 'constants/Mask';
import Placeholder from 'constants/Placeholder';
import CodeHelper from 'helpers/CodeHelper';
import withInputMask from 'hocs/withInputMask';
import useForm from 'hooks/useForm';

const MaskField = withInputMask(TextField);

/**
 * Поля формы.
 */
interface Fields {
  /**
   * Код подтверждения.
   */
  code: string;
}

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Текст на кнопке отправки формы.
   */
  submitText: string;

  /**
   * Указывает, что управляющие элементы должны быть заблокированы.
   */
  disabled?: boolean;

  /**
   * Обрабатывает успешную отправку формы.
   * @param code Введённый код подтверждения.
   */
  onSubmit?: (code: string) => void;
};

/**
 * Схема данных формы.
 */
const schema = Yup.object().shape({
  code: Yup.string().default('').required(FieldErrorText.EMPTY_CODE).code(),
});

/**
 * Отображает форму подтверждения через СМС.
 */
const LoginConfirmationForm: FC<Props> = ({
  submitText,
  disabled,
  onSubmit,
}) => {
  const form = useForm<Fields>({
    name: 'confirmation',

    validateOnSchemaChange: true,
    validationSchema: schema,

    onSubmit(values) {
      const code = CodeHelper.convertPrettyToSystem(values.code);
      onSubmit?.(code);
    },
  });

  return (
    <form {...form.bindForm()}>
      <Box marginY={2}>
        <MaskField
          {...form.bindTextField('code')}
          placeholder={Placeholder.CODE}
          mask={Mask.CODE}
          label="Код подтверждения"
          variant="filled"
          fullWidth
        />
      </Box>
      <Box marginY={2}>
        <Button
          {...form.bindSubmitButton()}
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          disabled={disabled}
        >
          {submitText}
        </Button>
      </Box>
    </form>
  );
};

export default LoginConfirmationForm;
